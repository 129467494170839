import React from 'react';
import '../styles/pages/_home.scss';
import Layout from 'src/layout/Layout';
import EncryptSnippet from '../../content/snippets/encrypt.md';
import Sample, { SampleTextContainer, SampleCode } from '../components/sections/sample/Sample';
import RedButton from '../components/buttons/RedButton';
import Feature, { FeatureProps } from '../components/feature/Feature';
import BlogPost, { BlogPostProps } from '../components/sections/blog/BlogPost';
import BlogPostList from '../components/sections/blog/BlogPostList';
import Cases from '../components/sections/cases/Cases';
import CaseItem, { CaseItemProps } from '../components/sections/cases/CasesItem';
import Conclusion from '../components/sections/conclusion/Conclusion';

import seoPage from '../../content/seo/index.yml';

import IconE2ee from '../styles/assets/images/features/icon-ee2e.svg';
import IconPassword from '../styles/assets/images/features/icon-password.svg';
import IconDb from '../styles/assets/images/features/icon-db.svg';
import IconCar from '../styles/assets/images/features/icon-car.svg';
import IconIot from '../styles/assets/images/features/icon-iot.svg';
import IconPlatform from '../styles/assets/images/features/Platform.svg';

import IconHealth from '../styles/assets/images/cases/Icon-Health.svg';
import IconFin from '../styles/assets/images/cases/Icon-Fin.svg';
import IconAuto from '../styles/assets/images/cases/Icon-Auto.svg';
import IconCloud from '../styles/assets/images/cases/Icon-Cloud.svg';
import IconIoT from '../styles/assets/images/cases/Icon-IoT.svg';
import IconIndustry from '../styles/assets/images/cases/Icon-Industry.svg';
import ReleaseButton from 'src/components/buttons/ReleaseButton';

const MAIN_FEATURES: FeatureProps[] = [
	{
		title: 'End-to-end encryption \n for communication',
		subtitle: 'Explore E3Kit',
		link: '/e3kit/',
		icon: IconE2ee,
	},
	{
		title: 'Secure Communications Platform\nfor enterprise privacy',
		subtitle: 'Explore Platform',
		link: '/secure-communications-platform/',
		icon: IconPlatform,
	},
	{
		title: 'Post-compromise protection \n for stored data',
		subtitle: 'Explore PureKit',
		link: '/purekit/',
		icon: IconDb,
	},
	{
		title: 'Vehicle-to-everything \n secure communication',
		subtitle: 'Explore WaveKit',
		link: 'https://developer.virgilsecurity.com/',
		icon: IconCar,
	},
	{
		title: 'Internet of Things \n lifecycle security',
		subtitle: 'Explore IoTKit',
		link: 'https://github.com/VirgilSecurity/virgil-iotkit',
		icon: IconIot,
	},
	{
		title: 'Password protection\nagainst hacking',
		subtitle: 'Explore PureKit',
		link: '/purekit/',
		icon: IconPassword,
	},
];

const LATEST_POSTS: BlogPostProps[] = [
	{
		title: 'Virgil PureKit: Protect passwords and stored data with post-compromise security',
		meta: 'Rebecca Yarbrough – March 18th, 2019',
		img: '/images/posts/Blog-4-700x265-c-default.jpg',
		href: '/blog/announcing-purekit/',
	},
	{
		title: 'Building End-to-End Encrypted Chat with E3Kit and Stream',
		meta: 'Rebecca Yarbrough – December 11th, 2019',
		img: '/images/posts/End-to-end-Encryption-with-Virgil-and-Stream-Chat-700x265-c-default.jpg',
		href: '/blog/end-to-end-encrypted-chat-with-e3kit-and-stream/',
	},
	{
		title:
			'Marriott’s $123 million mistake: \n A two-step technical guide to avoiding costly GDPR fines',
		meta: 'Rebecca Yarbrough – December 5th, 2019',
		img: '/images/posts/GDPR-Marriott-Breach-Blog-Post-Header-700x265-c-default.jpg',
		href: '/blog/marriott-gdpr-fine/',
	},
];

const ALL_CASES: CaseItemProps[] = [
	{
		title: 'Healthcare',
		description: 'Make your communication platform and backend HIPAA-compliant.',
		icon: IconHealth,
	},
	{
		title: 'Financial',
		description:
			'Manage cardholder data and personal financial information within a PCI DSS-compliant system.',
		icon: IconFin,
	},
	{
		title: 'Automotive',
		description: 'Build IEEE-1609.2-compliant security solutions for protecting V2X communication.',
		icon: IconAuto,
	},
	{
		title: 'Cloud',
		description: 'Protect and manage access to sensitive data in the сloud storage.',
		icon: IconCloud,
	},
	{
		title: 'Internet of Things',
		description: 'Secure IoT devices from manufacturing to the end-user experience.',
		icon: IconIoT,
	},
	{
		title: 'Industry 4.0',
		description:
			'Build a fully protected industrial ecosystem and securely manage it across the stack.',
		icon: IconIndustry,
	},
];

const IndexPage: React.FC = () => {
	return (
		<Layout seoPage={seoPage} className="homePage" data-vs-page="home">
			<section className="intro">
				<div className="wrapper">
					<div className="introContentBlock">
						<div className="blockMsg">
							<h1 className="blockMsg-headline">Essential security platform for your product</h1>
							<p className="blockMsg-text">
								Protect sensitive data and comply with HIPAA, GDPR, PCI DSS and more.
							</p>
							<ReleaseButton href="https://virgil.net" />
						</div>
						<section className="yourFavoriteTech">
							<div className="yourFavoriteTech-container">
								<div className="favoriteTech text">Works with your favorite tech</div>
								<a
									href="https://developer.virgilsecurity.com/docs/use-cases/v5/encrypted-communication-for-twilio"
									className="favoriteTech twillio"
								>
									<span role="img" aria-label="Twillio"></span>
								</a>
								<a
									href="https://developer.virgilsecurity.com/docs/use-cases/v5/encrypted-communication-for-nexmo"
									className="favoriteTech nexmo"
								>
									<span role="img" aria-label="Nexmo"></span>
								</a>
								<a
									href="https://virgilsecurity.com/end-to-end-encryption-for-back4app/"
									className="favoriteTech b4a"
								>
									<span role="img" aria-label="Back4App"></span>
								</a>
								<a
									href="https://developer.virgilsecurity.com/docs/use-cases/v5/encrypted-communication-for-firebase"
									className="favoriteTech firebase"
								>
									<span role="img" aria-label="Firebase"></span>
								</a>
								<a
									href="https://developer.virgilsecurity.com/docs/use-cases/v5/smart-door-lock"
									className="favoriteTech pubnub"
								>
									<span role="img" aria-label="PubNub"></span>
								</a>
							</div>
						</section>
					</div>
				</div>
			</section>
			<section className="solutions">
				<div className="wrapper">
					<h2 className="solutions-headline">Protect every aspect of your product.</h2>
					<p className="solution-subheader">
						Mix and match our toolkits to solve common security challenges.
					</p>
					<div className="solutions-cards">
						{MAIN_FEATURES.map((props, index) => (
							<Feature {...props} key={index} />
						))}
					</div>
				</div>
			</section>
			<Sample>
				<SampleTextContainer>
					<h3>
						From developers <br /> for developers
					</h3>
					<p>We provide cross-platform, transparent and easy-to-use libraries.</p>
					<RedButton href="https://developer.virgilsecurity.com/">Explore documentation</RedButton>
				</SampleTextContainer>
				<SampleCode>
					<EncryptSnippet />
				</SampleCode>
			</Sample>
			<BlogPostList>
				{LATEST_POSTS.map((post, index) => (
					<BlogPost {...post} key={index} />
				))}
			</BlogPostList>
			<Conclusion>
				<em>Get started</em> now with our easy-to-follow guides.
				<br />
				Start <em>free</em>, pay $0.02/month per user beyond 250 users.
			</Conclusion>
			<Cases>
				{ALL_CASES.map((item, index) => (
					<CaseItem {...item} key={index} />
				))}
			</Cases>
		</Layout>
	);
};

export default IndexPage;
