import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "hljs language-js"
      }}><span parentName="code" {...{
          "className": "hljs-comment"
        }}>{`// Encrypt and decrypt message`}</span>{`
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` usersToEncryptTo = [`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alice@myapp.com'`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'bob@myapp.com'`}</span>{`, `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'sofia@myapp.com'`}</span>{`];
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` userWhoEncrypts = `}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'alex@myapp.com'`}</span>{`;
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` [receiversCards, senderCard] = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "hljs-built_in"
        }}>{`Promise`}</span>{`.all([
	eThree.findUsers(usersToEncryptTo),
	eThree.findUsers(userWhoEncrypts),
])

`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` encryptedMsg = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authEncrypt(`}<span parentName="code" {...{
          "className": "hljs-string"
        }}>{`'Hello everyone!'`}</span>{`, receiversCards);
`}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`const`}</span>{` decryptedMsg = `}<span parentName="code" {...{
          "className": "hljs-keyword"
        }}>{`await`}</span>{` eThree.authDecrypt(encryptedMsg, senderCard);`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      