import React from 'react';
import { Link } from 'gatsby';
import BreakLinesTexts from '@virgilsecurity/virgil-ui/src/components/Text/BreakLinesTexts';

export interface FeatureProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	title: string;
	subtitle: string;
	icon: string;
	link?: string;
}

interface FeatureContentProps {
	title: string;
	subtitle: string;
	icon: string;
}

const FeatureContent: React.FC<FeatureContentProps> = ({ title, icon, subtitle }) => {
	return (
		<>
			<img alt={title} src={icon} className="solutions-image" />
			<h3 className="solutions-card-title">
				<BreakLinesTexts components={['b']}>{title}</BreakLinesTexts>
			</h3>
			<p className="solutions-card-text">{subtitle}</p>
		</>
	);
};

const Feature: React.FC<FeatureProps> = ({ title, subtitle, icon, link, ...props }) => {
	let className = 'solutions-card';

	if (!link) className += ' disabled';
	if (link && link[0] === '/') {
		return (
			<Link to={link} className={className}>
				<FeatureContent title={title} subtitle={subtitle} icon={icon} />
			</Link>
		);
	}
	return (
		<a className={className} href={link} {...props}>
			<FeatureContent title={title} subtitle={subtitle} icon={icon} />
		</a>
	);
};

export default Feature;
