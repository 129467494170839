import React from 'react';

export const SampleTextContainer: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
	return <div className="sample-text">{children}</div>;
};

export const SampleCode: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
	return <div className="sample-code">{children}</div>;
};

const Sample: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
	return (
		<section className="samples">
			<div className="wrapper">
				<div className="sample">{children}</div>
			</div>
		</section>
	);
};

export default Sample;
