import React from 'react';

const Conclusion: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
	return (
		<section className="conclusion">
			<div className="wrapper">
				<p className="conclusion-text">{children}</p>
				<div className="conclusion-links">
					<a className="button-raisedRed" href="https://dashboard.virgilsecurity.com/signup">
						Sign Up For Free
					</a>
					<a className="button-raisedRedBorder" href="/pricing/">
						Pricing
					</a>
				</div>
			</div>
		</section>
	);
};

export default Conclusion;
