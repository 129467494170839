import styled from 'styled-components';
import { ButtonContainer } from './Button.dump';
import DefaultBg from '../../styles/assets/images/bg/virgil-messenger/VM-Default.png';
import DefaultBg2x from '../../styles/assets/images/bg/virgil-messenger/VM-Default@2x.png';
import HoverBg from '../../styles/assets/images/bg/virgil-messenger/VM-Hover.png';
import HoverBg2x from '../../styles/assets/images/bg/virgil-messenger/VM-Hover@2x.png';
import PressedBg from '../../styles/assets/images/bg/virgil-messenger/VM-Pressed.png';
import PressedBg2x from '../../styles/assets/images/bg/virgil-messenger/VM-Pressed@2x.png';
import { withRetinaBackground } from '@virgilsecurity/virgil-ui/src/lib/styled';

const ReleaseButton = styled(ButtonContainer)`
	${withRetinaBackground(DefaultBg, DefaultBg2x)}
	background-size: contain;
	height: 60px;
	margin: 0 auto;
	width: 210px;

	&:hover {
		${withRetinaBackground(HoverBg, HoverBg2x)}
	}

	&:active {
		${withRetinaBackground(PressedBg, PressedBg2x)}
	}
`;

export default ReleaseButton;
